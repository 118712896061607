import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';
import { checkSelectedProduct, toWordCase } from '../../../shared/utils';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import Icon from '~common/atoms/Icon';
import Popover from 'components/Elements/Popover';
import { Flex } from 'rebass';

const StyledTabGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTabButton = styled.button`
  appearance: none;
  border: 0;
  border-radius: 0;
  position: relative;
  white-space: pre-line;
  width: 100%;
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 13px;
  padding: 8px 18px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out,
    0.2s color ease-in-out;
  background-color: ${props =>
    props.isSelectedProduct
      ? props.theme.colors.primary
      : props.theme.colors.lightBlue};
  border-color: ${props =>
    props.isSelectedProduct
      ? props.theme.colors.primary
      : props.theme.colors.lightBlue};
  color: ${props =>
    props.isSelectedProduct
      ? props.theme.colors.white
      : props.theme.colors.text};
  border-bottom: 4px solid ${props => props.theme.colors.primary} !important;

  &:hover {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }

  &:active {
    border-color: ${props => props.theme.colors.blue600};
    background-color: ${props => props.theme.colors.blue600};
    color: ${props => props.theme.colors.white};
  }

  &:disabled,
  &:disabled:hover {
    border-color: ${props => props.theme.colors.lightNeutral200};
    background-color: ${props => props.theme.colors.lightNeutral200};
    color: ${props => props.theme.colors.text};
  }

  > span {
    font-weight: normal;
    width: 100%;
  }

  &:before {
    content: 'Most popular';
    ${props => !props.isPopular && 'display: none'};
    position: absolute;
    top: -21px;
    left: 0;
    right: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 4px 0;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.secondary};
    text-align: center;
  }

  ${props =>
    props.addDividers
      ? `
    border-right: 1px solid ${props.theme.colors.primary};
    border-left: 1px solid ${props.theme.colors.primary};
  `
      : ''}
`;

const Tabs = ({ type, products, selectedProductCode, updateForm }) => {
  const handleClick = (isSelected, code) => {
    if (isSelected) {
      return;
    }
    updateForm(`${type}Product`, code);
  };

  return (
    <StyledTabGroup>
      {products
        .filter(product => product.allVariants.filter(v => v.onSale).length > 0)
        .map((product, index, filteredList) => {
          const { isSelected, variant } = checkSelectedProduct(
            product.variants,
            selectedProductCode
          );
          return (
            <StyledTabButton
              addDividers={
                !isSelected &&
                filteredList.length > 2 &&
                index > 0 &&
                index < filteredList.length - 1
              }
              key={product.id}
              onClick={() =>
                product.isValid ? handleClick(isSelected, variant.code) : null
              }
              isSelectedProduct={isSelected}
              isPopular={[
                'hospital-cover-prime-choice',
                'extras-cover-essential-extras',
              ].includes(product.slug.current)} // Needs revision in sanity
              disabled={!product.isValid}
            >
              {product.title} <span>{product.coverage}</span>
              {!product.isValid && (
                <span>(Only available with Smart Starter)</span>
              )}
            </StyledTabButton>
          );
        })}
    </StyledTabGroup>
  );
};

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const Legend = ({ type }) => {
  return (
    <StyledList>
      <StyledListItem>
        <StyledIcon
          aria-label="Inclusions"
          name="circle-fill-tick"
          fill="#569b07"
          width="16px"
          height="16px"
        />
        Inclusions
      </StyledListItem>
      {type !== 'extras' ? (
        <StyledListItem>
          <StyledIcon
            aria-label="Restricted"
            name="restricted"
            fill="#f5a623"
            width="16px"
            height="16px"
          />
          Restrictions
        </StyledListItem>
      ) : null}
      <StyledListItem>
        <StyledIcon
          aria-label="Exclusions"
          name="remove"
          fill="#d0021b"
          width="16px"
          height="16px"
        />
        Exclusions
      </StyledListItem>
    </StyledList>
  );
};

const StyledGapCoverage = styled.div`
  margin-top: 16px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  * {
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 18px;
  }

  strong {
    font-weight: 600;
  }
`;

// Hopefully just temporary styles while rebrand global styles are present
const StyledSanityRichTextContent = styled(SanityRichTextContent)`
  &&&&& .text-block {
    p,
    a {
      font-size: ${props => props.theme.fontSizes[2]}px;
      line-height: 18px;
      color: ${props => props.theme.colors.white};
    }
  }
`;

const GapCoverage = ({ gapCoverage }) => {
  return (
    <StyledGapCoverage>
      <strong>Gap Coverage</strong>
      <Flex justifyContent="space-between" alignItems="center" mt={8}>
        <Flex justifyContent="space-between" alignItems="center">
          <Icon
            name={gapCoverage.icon}
            originalColorSchema
            style={{ marginRight: 10 }}
          />
          {gapCoverage.title}
        </Flex>
        {gapCoverage.tooltip ? (
          <Popover
            position="top"
            content={
              <StyledSanityRichTextContent content={gapCoverage.tooltip} />
            }
          >
            <Icon
              name="circle-info"
              originalColorSchema
              style={{ marginLeft: 10 }}
            />
          </Popover>
        ) : null}
      </Flex>
    </StyledGapCoverage>
  );
};

const StyledCard = styled.div`
  background-color: ${props => props.theme.colors.white};
  position: relative;
`;

const StyledContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  text-align: center;
`;

const StyledType = styled.div`
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: ${props => props.theme.fontSizes[2]}px;
`;

const StyledTitle = styled.h4`
  font-size: ${props => props.theme.fontSizes[5]}px;
  line-height: 24px;
  font-weight: 700;
`;

const StyledSubtitle = styled.h4`
  font-size: ${props => props.theme.fontSizes[5]}px;
  line-height: 26px;
`;

const StyledPrice = styled.div`
  color: ${props => props.theme.colors.text};
  margin-top: 4px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 16px;

  span {
    font-weight: 600;
    font-size: ${props => props.theme.fontSizes[9]}px;
    line-height: 34px;
    margin-right: 4px;
  }
`;

const StyledExcess = styled.p`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  margin-top: 8px;
`;

const StyledDescription = styled.p`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  margin-top: 16px;
`;

const StyledSelected = styled.div`
  width: 100%;
  padding: 12px 16px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
`;

const MobileCard = ({
  excess,
  description,
  gapCoverage,
  paymentFrequency,
  price,
  products,
  selectedProductCode,
  subtitle,
  title,
  type,
  updateForm,
}) => {
  return (
    <>
      <Legend type={type} />
      <Tabs
        type={type}
        products={products}
        selectedProductCode={selectedProductCode}
        updateForm={updateForm}
      />
      <StyledCard>
        <StyledContainer>
          <StyledType>{type}</StyledType>
          <StyledTitle>{title}</StyledTitle>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
          <StyledPrice>
            <NumberFormat
              value={price}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />{' '}
            {toWordCase(paymentFrequency)}
          </StyledPrice>
          {type === 'hospital' ? (
            <StyledExcess>
              with {excess ? `$${excess}` : 'no'} excess
            </StyledExcess>
          ) : null}
          <StyledDescription>{description}</StyledDescription>
          {gapCoverage ? <GapCoverage gapCoverage={gapCoverage} /> : null}
        </StyledContainer>
        <StyledSelected>Selected cover</StyledSelected>
      </StyledCard>
    </>
  );
};

MobileCard.propTypes = {
  excess: PropTypes.number,
  description: PropTypes.string.isRequired,
  gapCoverage: PropTypes.object,
  paymentFrequency: PropTypes.string.isRequired,
  price: PropTypes.number,
  products: PropTypes.array.isRequired,
  selectedProductCode: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default MobileCard;
